<template>
  <v-container class="flex-column mt-1 register">
    <CategoryTitle :category="category" />

    <!-- <v-container pa-0> -->
    <v-form ref="form" v-model="isValid">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="registerData.person.firstName"
            label="Nome"
            :rules="[requiredRules()]"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="registerData.person.lastName"
            :rules="[requiredRules()]"
            label="Cognome"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="birthDate"
                label="Data di nascita"
                :rules="[requiredRules()]"
                required
                readonly
                outlined
                dense
              >
                <v-icon v-bind="attrs" v-on="on" slot="append"
                  >mdi-calendar</v-icon
                >
              </v-text-field>
            </template>
            <v-date-picker
              locale="it-IT"
              ref="menu"
              v-model="registerData.person.birthDate"
              :max="maxBirthDate"
              min="1910-01-01"
              first-day-of-week="1"
              type="date"
              no-title
              @input="menu = false"
              :active-picker.sync="activePicker"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="registerData.email"
            label="Indirizzo email"
            :rules="emailRules"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="registerData.password"
            label="Password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="registerData.confirmPassword"
            label="Conferma Password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordConfirm"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <!-- <v-col cols="12">
              <v-text-field
                v-model="registerData.billingAddress.addressType"
                label="Denominazione"
                
                outlined
                dense
                clearable
                :rules="requiredRules"
                required
              ></v-text-field>
            </v-col> -->
      </v-row>
    </v-form>
    <!-- </v-container> -->
    <div class="d-flex privacy-section">
      <v-checkbox
        color="primary"
        class="ma-0"
        hide-details
        v-model="privacySelected"
      >
        <template v-slot:label>
          <span
            >Dichiaro di aver visionato
            <strong>
              <!-- <router-link
                v-on:click.stop.prevent="() => {}"
                to="/page/privacy-policy"
                target="_blank"
              >
                l'informativa</router-link
              > -->
              <a @click.stop.prevent="goToPrivacy()" target="_blank">
                l'informativa</a
              >
            </strong>
            redatta ai sensi dell’art.13 del Regolamento Comunitario
            679/2016/UE.</span
          >
        </template>
      </v-checkbox>
    </div>
    <div class="my-8">
      <RecaptchaDisclaimer @click="clicked" />
    </div>
    <ResponseMessage :response="response" />
    <div class="row text-right">
      <div class="col-12">
        <v-btn
          class="primary registration-button"
          :disabled="disabledRegistration"
          @click="handleRegister"
          large
          depressed
          :loading="loading"
        >
          {{ $t("register.buttonRegister") }}
        </v-btn>
      </div>
    </div>
  </v-container>
</template>
<style scoped></style>
<script>
import IperalCustomService from "@/service/iperalCustomService";
import {
  requiredValue,
  isEmailIperal,
  isDate,
  isPasswordMinimumLengthIperal
} from "@/validator/validationRules";
import CategoryTitle from "@/components/category/CategoryTitle";
import categoryMixins from "~/mixins/category";
import clickHandler from "~/mixins/clickHandler";
import ResponseMessage from "@/components/ResponseMessage";
import analyticsService from "~/service/analyticsService";
import RecaptchaDisclaimer from "@/commons/components/RecaptchaDisclaimer.vue";
export default {
  name: "Register",
  props: ["nextUrl"],
  components: { CategoryTitle, ResponseMessage, RecaptchaDisclaimer },
  mixins: [categoryMixins, clickHandler],
  data() {
    return {
      maxBirthDate: this.$dayjs()
        .subtract(18, "year")
        .format("YYYY-MM-DD"),
      activePicker: null,
      menu: false,
      showPassword: false,
      registerData: {
        person: {
          firstName: null,
          lastName: null,
          birthDate: null
        },
        confirmPassword: null,
        email: null,
        password: null
        // billingAddress: {
        //   addressType: null,
        //   address1: null,
        //   addressNumber: null,
        //   postalCode: null,
        //   city: null,
        //   province: null
        // },
        // phone: null
      },
      privacySelected: false,
      isValid: false,
      lazy: true,
      requiredRules: requiredValue,
      dateRules: [requiredValue("Digitare la data di nascita"), isDate()],
      emailRules: [requiredValue("Digitare l'e-mail"), isEmailIperal()],
      passwordRules: [
        requiredValue("Digitare la password"),
        isPasswordMinimumLengthIperal()
      ],
      passwordConfirm: [
        v => !!v || "Confermare la password",
        v => v === this.registerData.password || "Le password non coincidono"
      ],
      errors: [],
      response: {},
      loading: false
    };
  },
  computed: {
    disabledRegistration() {
      return !this.isValid || !this.privacySelected;
    },
    birthDate: {
      get: function() {
        if (this.registerData.person.birthDate) {
          return this.$dayjs(this.registerData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        this.registerData.person.birthDate = this.$dayjs(value).format(
          "DD/MM/YYYY"
        );
      }
    }
  },
  methods: {
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    goToPrivacy() {
      window.open("/page/privacy-policy", "_blank");
    },
    async handleRegister() {
      try {
        this.response = {};
        this.loading = true;
        if (this.$refs.form.validate()) {
          this.registerData.login = this.registerData.email;
          // self = this;
          let res = await IperalCustomService.registrationUser(
            this.registerData
          );
          console.log(res);
          this.loading = false;

          if (res.response && res.response.status === 0) {
            analyticsService.signUp();
            this.$router.push({
              path: "/registration-completed",
              name: "RegistrationCompleted"
            });
          } else {
            this.response = res.response;
          }
        }
      } catch (response) {
        this.response = response;
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    }
  }
};
</script>
